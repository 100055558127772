<template>
    <div>
        <CCollapse :show="activeMovementItem(1)">
             <PendingsBls/> 
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import PendingsBls from '../warehouse/pendingsBls';

    //methods
    function activeMovementItem(item) {
        return this.dropItemMovementList === item;
    }

    export default {
        name:"movements-list-index",
        methods:{
            activeMovementItem,
        },
        components:{
            PendingsBls,
        },
        computed:{
            ...mapState({
                dropItemMovementList: state => state.yardManagement.dropItemMovementList,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>