<template>
    <div class="mb-3">
        <div v-show="showIndex==0">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.blsPendingReceive')}`}} </h6>
                </CCol>
            </CRow>
            <CRow class="mt-2">
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                            square
                            size="sm"
                            color="watch"
                            class="d-flex align-items-center"
                            @click="toggleAdd(item)"
                            v-c-tooltip="{
                                content: $t('label.reception'),
                                placement: 'top-start'
                            }"
                            >
                            <CIcon name='cil-list' />
                            </CButton>
                        
                        </td>
                    </template>

                    <template #Weight="{ item }">
                        <td class="text-center">
                            {{ NumberFormat(item.Weight, 2) }}
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <BlRecepcion
                :Active="showIndex==1"
                :ContainerItem="ContainerItem"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="Update"
            />
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import BlRecepcion from './BlReception/reception';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            Items: [],
            ContainerItem:{},
            dataContainer: [],
            showIndex: 0,
        };
    }

    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    async function getBlVisitReception(loading) {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('YardWVisitBillOfLadingPendingReception-list', { CompanyBranchId: this.CompanyBranchId })
            .then(response => {
                let List = response.data.data;
                this.dataContainer = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(() => {
                this.$store.state.yardManagement.loading = loading;
                if (this.FgPositioning) {
                    this.$store.state.yardManagement.ContainerTabIndex = 2;
                    this.$store.state.yardManagement.dropContainerMovement = 3;
                    this.$store.state.yardManagement.yardCollapse = 2;
                }
            });
    }

    async function Update(loading) {
        await this.getBlVisitReception(loading);
    }

    function toggleAdd(item) {
        this.ContainerItem = item;
        this.showIndex = 1;
    }
    
    //computed
    function computedListContainer() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return this.dataContainer.map((item, index) => {
            return {
                ...item,
                Nro: index+1,
                TpCargoName: item[`TpCargoName${_lang}`] ?? 'N/A',
                VesselVoyage: `${item.VesselName} - ${item.Voyage}`,
                Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A',
                CommodityName: item[`CommodityName${_lang}`] ?? 'N/A',
                FirstEventName: item[`FirstEventName${_lang}`] ?? 'N/A',
                FirstEventDate: item.FirstEventDate ? DateFormater.formatDateTimeWithSlash(item.FirstEventDate) : '',
                LastEventName: item[`LastEventName${_lang}`] ?? 'N/A',
                LastEventDate: item.LastEventDate ? DateFormater.formatDateTimeWithSlash(item.LastEventDate) : '',
                VisitBillOfLadingStatus: item[`VisitBillOfLadingStatus${_lang}`] ?? 'N/A',
                YardCargoBlStatus: item[`YardCargoBlStatusEs${_lang}`] ?? 'N/A',
            };
        })
    }

    function fields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'width:50px; text-align:center', _classes: 'text-center', filter: false },
            { key: 'NroBl', label: this.$t('label.bl'),  _style:'min-width:120px; text-align:center;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'ClientName', label: this.$t('label.client'), _style:'width:40%', _classes:'text-uppercase text-center center-cell' },
            { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:230px;', _classes:'text-center' },
            { key: 'VesselVoyage', label: this.$t('label.visit'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px; text-align:center;', sorter: true, filter: true},
            { key: 'Ata', label: 'ATA',  _classes: 'text-uppercase text-center', _style: 'min-width:150px;  text-align:center;', sorter: true, filter: true},
            { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
            { key: 'CommodityName', label: this.$t('label.commodity'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
            { key: 'Weight', label: `${this.$t('label.weight')} (KGMS)`,  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
            { key: 'QuantityRemain', label: this.$t('label.remainder'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
            { key: 'FirstEventName', label: this.$t('label.firstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px; text-align:center;', sorter: true, filter: true},
            { key: 'FirstEventDate', label: this.$t('label.dateFierstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px; text-align:center;', sorter: true, filter: true},
            { key: 'LastEventName', label: this.$t('label.LastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px; text-align:center;', sorter: true, filter: true},
            { key: 'LastEventDate', label: this.$t('label.DateLastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px; text-align:center;', sorter: true, filter: true},
            { key: 'DaysInPort', label: this.$t('label.daysInPort'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px; text-align:center;', sorter: true, filter: true},
            { key: 'VisitBillOfLadingStatus', label: this.$t('label.stowageStatus'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px; text-align:center;', sorter: true, filter: true},
            { key: 'YardCargoBlStatus', label: this.$t('label.statusYardWarehouse'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px; text-align:center;', sorter: true, filter: true},  
        ];
    }

    export default{
        name: 'equipment',
        data,
        mixins: [General],
        components: {
            BlRecepcion,
        },
        methods: {
            toggleAdd,
            getBlVisitReception,
            Update,
            NumberFormat
        },
        computed: {
            fields,
            computedListContainer,
            ...mapState({
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                YardId: state => state.yardManagement.yardData.YardId,
                FgPositioning: state => state.yardManagement.FgPositioning,
                dropItemMovementList: state => state.yardManagement.dropItemMovementList,
            })
        },
        watch:{
            dropItemMovementList: function (val) {
                if (val==1) {
                    this.showIndex = 0;
                    this.dataContainer = [];
                    this.getBlVisitReception(false);
                }
            }
        }
    }
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>